<template>
  <div class="boosted-odds-game-row row px-2">
    <div class="col-4" v-for="f in fixture.outcomes" :key="f.odds">
      <div
        style="font-weight: 600"
        class="boosted-a-1 text-center odd-indicator"
      >
        {{ f.alias }}
      </div>
      <div class="col text-center bold">
        <button
          :id="
            getOddID(
              fixture.match_id,
              f.market_id,
              f.outcome_id,
              f.specifier,
              'fodd'
            )
          "
          @click="
            addFreebetPick(
              fixture.match_id,
              f.market_id,
              home_team,
              away_team,
              f.active,
              f
            )
          "
          v-bind:disabled="parseInt(fixture.status) === 1"
          style="
            margin: 0 !important;
            font-weight: 600 !important;
            padding: 0.5rem 0.75rem;
          "
          class="btn odd-btn small-txt mt-1"
          :class="getClasses(f)"
        >
          <div>
            <span v-if="parseInt(fixture.status) === 1">-</span>
            <span>{{ parseFloat(f.odds).toFixed(2) }}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn.odd-btn.small-txt {
  margin-right: 5px;
}
</style>

<script>
export default {
  name: "FreebetOdd",
  props: {
    match_id: {
      required: true,
    },
    /*outcome_id: {
      required: true,
    },
    market_id: {
      required: true,
    },
    specifier: {
      required: true,
    },*/
    fixture: {
      required: true,
    },
    outcomes: {
      required: true,
    },
  },
  methods: {
    home_team: function () {
      return this.getHomeCompetitorName(this.fixture.name);
    },

    away_team: function () {
      return this.getAwayCompetitorName(this.fixture.name);
    },
    getClasses: function (o) {
      if (o === undefined || this.odd_id === undefined || o === false) {
        return " match-" + this.fixture.match_id;
      }

      var isPicked = o.odd_id === this.odd_id ? "picked" : "";

      var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? "" : "";
      return st + " " + isPicked + " match-" + this.fixture.match_id;
    },

    updatePick(update) {
      var vm = this;
      vm.pick = { ...this.pick, ...update };
    },
    oddsChange: function (payload) {
      var vm = this;

      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if (parseFloat(payload.odd) !== parseFloat(vm.odd)) {
        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked =
        parseInt(vm.oddStatus) > 0 ||
        parseInt(vm.oddActive) === 0 ||
        parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();
    },
    getOddDirectionClass: function () {
      if (
        parseInt(this.producerStatus) === 0 ||
        parseInt(this.oddActive) === 0 ||
        parseInt(this.oddStatus) !== 0
      ) {
        return "";
      }

      if (parseFloat(this.odd) >= parseFloat(this.previousOdds)) {
        return "arrow green up";
      }
      return "arrow red down";
    },
    getStatusClass: function () {
      this.statusClass = this.locked ? "lock" : "";
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }
      return parseFloat(x).toFixed(2);
    },
    addFreebetPick: function (
      match_id,
      market_id,
      competitor_1,
      competitor_2,
      active,
      picks
    ) {
      var status = picks.status;

      if (parseInt(active) === 0 || parseInt(status) !== 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        // return
      }

      var odd_id = picks.odd_id;
      var bSlip = this.getObject("fslip");

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      var isunselect = false;
      picks.match_id = match_id;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;

      if (!bSlip || bSlip.length === 0) {
        bSlip = [];
      }

      if (bSlip.length > 0) {
        bSlip = bSlip.filter((v) => {
          if (v !== undefined && v !== null) {
            if (v.odd_id === odd_id) {
              isunselect = true;
            }
            return v.match_id !== match_id;
          }
          return false;
        });
      }

      if (!isunselect) {
        bSlip.push(picks);
      }

      var bt = bSlip.filter((v) => v !== undefined && v);

      this.saveObject("fslip", bt);

      // console.log("Number of selected odds: " + bt.length);

      this.autoRefreshFreebetUI("addFreebetPick");

      // console.log("After selection: odd_id=" + odd_id + ", isunselect=" + isunselect);
    },
  },
  mounted: function () {
    // this.id = this.getOddID(this.match_id,this.market_id,this.outcome_id,this.specifier,'fodd');
    var vm = this;
    this.odd = this.odds;
    this.oddStatus = this.status;
    this.oddActive = this.active;
    this.previousOdds = this.previous_odds;
    this.producerStatus = this.producer_status;

    // console.log("Producer status in Odds", this.oddStatus)

    /*if (parseInt(this.producer_id) === 1) {
      this.locked =
          parseInt(this.oddStatus) > 0 ||
          parseInt(this.oddActive) === 0 ||
          parseInt(this.producerStatus) === 0;
    } else {
      this.locked =
          parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0;
    }*/

    /*if(parseInt(this.producer_id) === 3 ) {

      this.locked = parseInt(this.oddStatus) === 0 || parseInt(this.oddActive) === 0 || parseInt(this.producerStatus) === 0;

      } else {

      this.locked = parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0;
    }*/

    if (parseInt(this.producer_id) === 3) {
      vm.locked =
        parseInt(vm.oddStatus) === 1 ||
        parseInt(this.oddActive) === 0 ||
        parseInt(vm.producerStatus) !== 1;
    } else {
      vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0;
    }

    this.getStatusClass();

    this.id = this.getOddID(
      this.match_id,
      this.market_id,
      this.outcome_id,
      this.specifier,
      this.boosted ? "boosted" : "odd"
    );
    this.directionClass = this.getOddDirectionClass();

    this.EventBus.$on("odds:" + vm.id, function (payload) {
      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if (parseFloat(payload.odd) !== parseFloat(vm.odd)) {
        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked =
        parseInt(vm.oddStatus) > 0 ||
        parseInt(vm.oddActive) === 0 ||
        parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();
    });

    this.EventBus.$on("producer:status", function (payload) {
      var producer_id = payload.producer_id;
      var producer_status = this.producer_status;

      if (parseInt(producer_id) === 1) {
        vm.producerStatus = producer_status;
        vm.directionClass = vm.getOddDirectionClass();
        vm.locked =
          parseInt(vm.oddStatus) > 0 ||
          parseInt(vm.oddActive) === 0 ||
          parseInt(producer_status) === 0;
        vm.getStatusClass();
      }
    });

    this.EventBus.$on("betstop:match-:" + vm.match_id, function () {
      console.log("Got betstop:match-" + vm.match_id);
      vm.oddStatus = 1; // deactivate
      vm.locked = true;
      vm.getStatusClass();
    });

    this.EventBus.$on(
      "status:match-:" + vm.match_id + ":market-" + vm.market_id,
      function (payload) {
        vm.oddStatus = payload.status; // deactivate
        vm.locked = parseInt(vm.oddStatus) > 0;
        vm.getStatusClass();
      }
    );

    this.EventBus.$on(
      "oddschange:match-:" +
        vm.match_id +
        ":market-" +
        vm.market_id +
        ":outcome-" +
        vm.outcome_id +
        ":specifier-" +
        vm.specifier,
      function (payload) {
        vm.oddActive = payload.active;

        if (parseFloat(payload.odd) !== parseFloat(vm.odd)) {
          vm.previousOdds = vm.odd;
          vm.odd = payload.odd;
        }

        vm.locked =
          parseInt(vm.oddStatus) > 0 ||
          parseInt(vm.oddActive) === 0 ||
          parseInt(vm.producerStatus) === 0;
        vm.getStatusClass();

        vm.directionClass = vm.getOddDirectionClass();
      }
    );
  },
  data: function () {
    return {
      iconSize: 13,
      id: "",
      odd: "",
      directionClass: "",
      oddStatus: "",
      oddActive: "",
      previousOdds: "",
      producerStatus: "",
      locked: false,
      statusClass: "",
    };
  },
  computed: {
    isHot: function () {
      return parseFloat(this.probability) > 0.65;
    },
  },
  components: {
    // LockIcon,
  },
  watch: {
    producer_id: {
      immediate: true,
      handler(newVal) {
        // Update pick object with producer_id
        this.updatePick({ producer_id: newVal });
      },
    },
    market_id: {
      immediate: true,
      handler(newVal) {
        // Update pick object with market_id
        this.updatePick({ market_id: newVal });
      },
    },
  },
};
</script>
