var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boosted-odds-game-row row px-2"},_vm._l((_vm.fixture.outcomes),function(f){return _c('div',{key:f.odds,staticClass:"col-4"},[_c('div',{staticClass:"boosted-a-1 text-center odd-indicator",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(f.alias)+" ")]),_c('div',{staticClass:"col text-center bold"},[_c('button',{staticClass:"btn odd-btn small-txt mt-1",class:_vm.getClasses(f),staticStyle:{"margin":"0 !important","font-weight":"600 !important","padding":"0.5rem 0.75rem"},attrs:{"id":_vm.getOddID(
            _vm.fixture.match_id,
            f.market_id,
            f.outcome_id,
            f.specifier,
            'fodd'
          ),"disabled":parseInt(_vm.fixture.status) === 1},on:{"click":function($event){return _vm.addFreebetPick(
            _vm.fixture.match_id,
            f.market_id,
            _vm.home_team,
            _vm.away_team,
            f.active,
            f
          )}}},[_c('div',[(parseInt(_vm.fixture.status) === 1)?_c('span',[_vm._v("-")]):_vm._e(),_c('span',[_vm._v(_vm._s(parseFloat(f.odds).toFixed(2)))])])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }